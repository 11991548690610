import {createContext} from 'react';

type ContextState = {
    activeTab: string;
    activeProject: string;
};

const AppContext = createContext<ContextState>({
    activeTab: 'profile',
    activeProject: 'truckhoming'
});

export default AppContext;