import React, {useContext, ReactNode, useState, createContext, Dispatch, SetStateAction} from "react";
import styled from "styled-components";
import * as constants from '../constants';

type ProfileContextState = {
    openSection: string;
};

const ProfilePageContext = createContext<ProfileContextState>({
    openSection: 'experience',
});

const headingHeight = 2.7

const Heading = styled.div`
    display: inline-block;
    font-size: 2vw;
    line-height: ${headingHeight}vw;
    font-weight: 300;
    text-align: left;
`

const HeadingDiv = styled.div`
    border: thin black solid;
    border-right: 0px;
    border-top: 0px;
    padding-left: 1vw;
    height: ${headingHeight}vw;
    width: 63vw;
    .heading::after {
        content: "";
        height: 100%;
        display: inline-block;
    }
`

const Strut = styled.div`
    height: ${headingHeight}vw;
    display: inline-block;
`

const Paragraph = styled.p`
    font-size: .9vw;
    text-align: left;
    margin: .3vh 1vw;
`

const WorkDiv = styled.div`
    margin-top: 2vh;
`

const WorkHeaderDiv = styled.div`
    font-size: 1vw;
    text-align: left;
    span {
        margin-left: 1vw;
    }
`

type WorkProps = {
    from: number;
    to: number;
    company: string;
    location: string;
    description: Array<Array<string>>; // description points
}

const ProfileDiv = styled.div`
    margin-top: ${headingHeight}vh;
`
function Profile () {
    return (
        <ProfileDiv>
            <Paragraph>
                A creative, open-minded problem solver, passionate about artificial intelligence, deep learning, and computer vision. Loves to work in diverse teams, read AI papers, and apply their expertise to the most interesting and challenging problems in AI. Values clean code and efficient architectures. Designs, prototypes, develops, and deploys projects on a daily basis. 
            </Paragraph>
        </ProfileDiv>
    )
}

function Work (props: WorkProps) {
    var description = Array()
    Object.entries(props.description).forEach(([i, [point, desc]]) => {
        console.log(point, desc)
        description.push(<Paragraph><b>{point}</b> {desc}</Paragraph>)
    });
    return (
        <WorkDiv>
            <WorkHeaderDiv>
                <span>{props.from} - {props.to}</span>
                <span>{props.company}</span>
                <span>{props.location}</span>
            </WorkHeaderDiv>
            <div className="description">{description}</div>
        </WorkDiv>
    )
}

const work_experiences = {
    "work_exp": [
        {
            from: 2021,
            to: 2024,
            company: 'AI Engineer @ Minealytics',
            location: 'Melbourne, Australia (Remote)',
            description: [
                [
                    "Computer Vision",
                    "Developed surveillance applications using Object Detection and Semantic Segmentation, and forecasting applications using Image Regression models. Optimized models using TensorRT for edge computing. Created user interfaces for monitoring Computer Vision applications in React. Automated pre-labeling for Object Detection training."
                ],
                [
                    "Time Series Analysis",
                    "Designed a new architecture for multi-feature time series analysis, used in autonomous equipment control, forecasting, and optimization. Created a visualization system using InfluxDB and Grafana for monitoring training and on-site performance."
                ],
                [
                    "Reinforcement Learning",
                    "Created a proof-of-concept autonomous robotic control system based on a simulated environment using DPPG."
                ]
            ],
        },
        {
            from: 2024,
            to: 2024,
            company: 'LLM Training Engineer @ Scale AI',
            location: '(Remote)',
            description: [
                [
                    "Large Language Models",
                    "Created, reviewed, and analyzed data for programming-focused LLM training.",
                ]
            ],
        },
        {
            from: 2022,
            to: 2023,
            company: 'AI Teacher @ Udacity',
            location: '(Remote)',
            description: [
                [
                    "Teaching AI",
                    "Taught 80 students AI theory and applications through weekly sessions and mentored them. ",
                ]
            ],
        },
        {
            from: 2024,
            to: 2024,
            company: 'AI Researcher @ neurocat',
            location: 'Berlin, Germany',
            description: [
                [
                    "AI Robustness",
                    "Collected and organized image corruptions for ADAS AI training. Created a test suite for corruption robustness in the company product.",
                ],
                [
                    "Explainable AI",
                    "Taxonomised Explainable AI methods based on model use-architectures and other criteria. Tested and compared many existing methods and designed new methods based on found shortcomings. Created a new explanation method for Semantic Segmentation models."
                ]
            ],
        },
        
    ]
}

type ExperienceProps = {
    setOpenSection: Dispatch<SetStateAction<string>>
}

function Experience(props: ExperienceProps) {
    const profilePageContext = useContext(ProfilePageContext)
    const experiences = work_experiences.work_exp.map((value, i) => (
        <Work {...value} ></Work>
    ));
    return (
        <div>
            <HeadingDiv onClick={
                () => profilePageContext.openSection === 'experience' 
                ? props.setOpenSection('education') : props.setOpenSection('experience')
            }>
                <Heading className="heading">experience</Heading>
                <Strut className="strut"></Strut>
            </HeadingDiv>
            {profilePageContext.openSection === 'experience' ? experiences : ''}
        </div>
       
    )
}

const education_list = {
    "universities": [
        {
            from: 2019,
            to: 2021,
            university: 'Maastricht University',
            location: 'Maastricht, Netherlands',
            gpa: 8.5,
            gpamax: 10,
            description: [
                [
                    "Semantic Segmentation Explanations",
                    "Developed a novel method for explaining semantic segmentation models such as DeepLabv3."
                ],
            ],
        },
        {
            from: 2015,
            to: 2019,
            university: 'Pazmany Peter Catholic University',
            location: 'Budapest, Hungary',
            gpa: 4.5,
            gpamax: 5,
            description: [
                [
                    "Anomaly Detection in EEG",
                    "Investigated the potential of auto-encoders in anomaly detection in human EEG data."
                ],
            ],
        }
    ]
}



type UniversityProps = {
    from: number;
    to: number;
    university: string;
    location: string;
    gpa: number;
    gpamax: number;
    description: Array<Array<string>>; // description points
}

const UniversityDiv = styled.div`
    margin-top: 2vh;
`

const UniversityHeaderDiv = styled.div`
    font-size: 1vw;
    text-align: left;
    span {
        margin-left: 1vw;
    }
`

function University (props: UniversityProps) {
    var description = Array()
    Object.entries(props.description).forEach(([i, [point, desc]]) => {
        description.push(<Paragraph><b>{point}</b> {desc}</Paragraph>)
    });
    return (
        <UniversityDiv>
            <UniversityHeaderDiv>
                <span>{props.from} - {props.to}</span>
                <span>{props.university}</span>
                <span>{props.location}</span>
                <span>GPA: {props.gpa}/{props.gpamax}</span>
            </UniversityHeaderDiv>
            <div className="description">{description}</div>
        </UniversityDiv>
    )
}

type EducationProps = {
    setOpenSection: Dispatch<SetStateAction<string>>
}


function Education(props: EducationProps) {
    const profilePageContext = useContext(ProfilePageContext)
    const education = education_list.universities.map((value, i) => (
        <University {...value} ></University>
    ));
    return (
        <div>
            <HeadingDiv onClick={
                () => profilePageContext.openSection === 'education' 
                ? props.setOpenSection('experience') : props.setOpenSection('education')
            }>
                <Heading className="heading">education</Heading>
                <Strut className="strut"></Strut>
            </HeadingDiv>
            {profilePageContext.openSection === 'education' ? education : ''}
        </div>
       
    )
}

export default function ProfilePage() {
    const [openSection, setOpenSection] = useState('experience')
    return (
        <ProfilePageContext.Provider value={{openSection: openSection}}>
            <div>
                <Profile />
                <Experience setOpenSection={setOpenSection}/>
                <Education setOpenSection={setOpenSection}/>
            </div>
        </ProfilePageContext.Provider>
    )
}