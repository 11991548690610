import React, { useState } from 'react';
import './App.css';
import {Header, HeaderProps} from'./components/header';
import AppContext from './AppContext';
import SideBar from './components/sidebar';
import {styled} from 'styled-components';
import * as constants from './constants';
import ProfilePage from './components/profile-page';

const ContentDiv = styled.div`
    position: absolute;
    width: 65vw;
    left: ${constants.sideBarWidth}vw;
    top: ${constants.headerHeight}vh;
`

function App() {
    const [activeTab, setActiveTab] = useState("profile");
    const [activeProject, setActiveProject] = useState("profile");
    const content = activeTab === 'profile' ? (<ProfilePage /> ) : (<div></div>)
    const tabbedHeaderProps: HeaderProps = {
        tabs: {
            profile: {
                name: 'profile',
                id: 'profile',
                className: '',
                onclick: (event: React.MouseEvent) => {
                    setActiveTab('profile');
                },
            },
            projects: {
                name: 'projects',
                id: 'projects',
                className: '',
                onclick: (event: React.MouseEvent) => {
                    setActiveTab('projects');
                }
            }
        }
    }
    return (
        <div className="App">
            <AppContext.Provider 
                value={{
                    activeTab: activeTab,
                    activeProject: activeProject
                }}
            >
                <Header {...tabbedHeaderProps} />
                <SideBar />
                <ContentDiv>
                    {content}
                </ContentDiv>
            </AppContext.Provider>
        </div>
    )
}

export default App;
