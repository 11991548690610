import React, {useContext, ReactNode} from "react";
import styled from "styled-components";
import AppContext from '../AppContext';
import * as constants from '../constants';


type TabProps = {
    id: string;
    name: string;
    onclick: (event: React.MouseEvent) => void;
    className: string;
}

const CenteredSpan = styled.div<{ $lheight: number}>`
    line-height: ${props => props.$lheight}vh;
`

const TabDiv = styled.div`
    display: inline-flex;
    font-weight: 400;
    margin: 0px;
    height: 100%;
    vertical-align: center;
    align-content: center;
    justify-content: center;
    width: 10vw;
    &:hover{
        background-color: #eee;
    }
    &.active {
        background-color: #ddd;
    }
`

function Tab(props: TabProps, ){
    return (
        <TabDiv 
            id={props.id}
            onClick={props.onclick}
            className={props.className + " tab"}
        >
            <CenteredSpan $lheight={constants.headerHeight}>{props.name}</CenteredSpan>
        </TabDiv>
    )
}

export type HeaderProps = {
    tabs: Record<string, TabProps>;
}

const TabsContainer = styled.div`
    display: flex;
    justify-content: left;

`

const NameDiv = styled.div`
    border-right: 1px solid black;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 17.5vw;
    &span {
        margin: auto;
    }
`

function Name() {
    return (
        <NameDiv id="name">
            <CenteredSpan $lheight={constants.headerHeight}>Attila Herbert</CenteredSpan>
        </NameDiv>
    )
}

const HeaderTag = styled.header`
    display: flex;
    font-size: 1.7vw;
    justify-content: space-between;
    border-bottom: 1px solid black;
    top: 0px;
    left: 0px;
    height: ${constants.headerHeight}vh;
    width: 100%;
    & div {
        display: flex;
    }
`

export function Header(props: HeaderProps) {
    const appContext = useContext(AppContext);
    const tabsItems: ReactNode[] = [];
    for (let key in props.tabs){
        let tabProps = props.tabs[key]
        tabsItems.push(
            <Tab
                key={key}
                name={tabProps.name}
                id={tabProps.id}
                onclick={tabProps.onclick}
                className={appContext.activeTab === key ? "active" : ""}
            ></Tab>
        )
    }
    return (
        <HeaderTag >
            <div>
                <Name />
                <TabsContainer >
                    {tabsItems}
                </TabsContainer>
            </div>
        </HeaderTag>
    )
}