import React, {useContext, ReactNode} from "react";
import styled from "styled-components";
import * as constants from '../constants';

const ImageDiv = styled.div`
    height: ${constants.sideBarWidth}vw;
    width: ${constants.sideBarWidth}vw;
    align-content: center;
    justify-content: center;
    text-align: center;
    img {
        height: 75%;
        width: 75%;
        border: 1px solid black;
    }
`

function Image() {
    return (
        <ImageDiv>
            <img src='pfp.jpeg' alt='profile picture' id='pfp'/>
        </ImageDiv>
    )
}

function Contact() {
    return (
        <div></div>
    )
}

const SideBarDiv = styled.div`
    width: ${constants.sideBarWidth}vw;
    height: ${100 - constants.headerHeight}vh;
    border-right: 1px solid black;
`

export default function SideBar() {
    return (
        <SideBarDiv>
            <Image />
        </SideBarDiv>
    )
}